import { FinanceInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

const sales = {
  state: {
    salesSummary: null,
    salesDetail: null,
    salesSeries: null
  },
  getters: {
    salesSummary: state => {
      return state.salesSummary ? state.salesSummary.items : false
    },
    salesCursor: state => {
      return state.salesSummary ? state.salesSummary.cursor : false
    },
    salesSeries: state => {
      return state.salesSeries ? state.salesSeries.series : false
    },
    salesDetailAcquirer: state => {
      return state.salesDetail ? state.salesDetail.acquirer.items : false
    },
    salesDetailNetwork: state => {
      return state.salesDetail ? state.salesDetail.network.items : false
    },
    salesDetailClassification: state => {
      return state.salesDetail ? state.salesDetail.classification.items : false
    },
    salesDetailInstallment: state => {
      return state.salesDetail ? state.salesDetail.installment.items : false
    }
  },
  mutations: {
    SET_SALES_SUMMARY (state, salesSummary) {
      state.salesSummary = salesSummary
    },
    SET_SALES_DETAIL (state, salesDetail) {
      state.salesDetail = salesDetail
    },
    SET_SALES_SERIES (state, salesSeries) {
      state.salesSeries = salesSeries
    }
  },
  actions: {
    GET_SALES_SUMMARY ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_SALES_SUMMARY', null)
      FinanceInstance.sale.getSummary(merchantId, query)
        .then(response => commit('SET_SALES_SUMMARY', response))
        .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
    },
    GET_SALES_SERIES ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_SALES_SERIES', null)
      return FinanceInstance.sale.getSeries(merchantId, query)
        .then(response => commit('SET_SALES_SERIES', response))
        .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
    },
    GET_SALES_DETAIL ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_SALES_DETAIL', null)
      return Promise.all([
        FinanceInstance.sale.getDetail('acquirer', merchantId, query),
        FinanceInstance.sale.getDetail('network', merchantId, query),
        FinanceInstance.sale.getDetail('classification', merchantId, query),
        FinanceInstance.sale.getDetail('installment', merchantId, query)
      ])
        .then(([acquirer, network, classification, installment]) => {
          commit('SET_SALES_DETAIL', {
            acquirer: acquirer,
            network: network,
            classification: classification,
            installment: installment
          })
        })
        .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
    }
  }
}
export default sales
