<template>
  <div class="el-bounds">
    <div class="el-section">
      <div class="emd-container _highlight_header _header_spaced _footer_spaced">
        <div class="emd-container__header">
          <h2 class="emd-text _size_section">Finance</h2>
        </div>
        <div class="eu-align_center eu-pa_2">
          <div class="el-section">
            <div class="emeraldraws">
              <emd-draw-empty-list />
            </div>
          </div>
          <div class="el-chunk">
            <p class="emd-text _color_subtle _type_caption _size_subpage">
              {{$t('title')}}
            </p>
            <p class="emd-text _color_subtle _type_caption eu-mb_3">
              {{$t('subtitle')}}
            </p>
            <p class="emd-text _color_subtle _type_caption _size_section">
              {{$t('phone')}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<i18n>
{
  "pt-BR": {
    "title": "Você ainda não tem acesso a esse serviço.",
    "subtitle": "Converse com a nossa equipe e saiba como adquirir os benefícios da nossa gestão financeira.",
    "phone": "4003 2022",
    "unavailable": "Sem acesso",
    "backofficeLink": "Retornar ao Backoffice"
  },
  "en-US": {
    "title": "You don't have access to this service yet.",
    "subtitle": "Talk to our team and learn how to gain the benefits of our financial management.",
    "phone": "+55 4003 2022",
    "unavailable": "Unavailable",
    "backofficeLink": "Return to Backoffice"
  }
}
</i18n>

<script>
import EmdDrawEmptyList from '@/assets/emeraldraws/empty-list.svg?inline'

export default {
  name: 'Unavailable',
  components: {
    EmdDrawEmptyList
  },
  computed: {

  },
  methods: {
    resetSearch () {
      this.$emit('resetClicked')
    }
  },
  beforeMount () {
    document.title = `${this.$t('unavailable')} - Mundi Finance`
  }
}
</script>
