import { Bar, mixins } from 'vue-chartjs'

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    chartOptions: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.chartOptions)
  },
  watch: {
    chartOptions () {
      this.renderChart(this.chartData, this.chartOptions)
    },
    chartData () {
      this.renderChart(this.chartData, this.chartOptions)
    }
  }
}
