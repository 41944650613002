<template>
  <div class="emd-container _highlight_header">
    <div class="emd-container__header">
      <h2 class="emd-text _size_section">{{$t('title')}}</h2>
      <router-link :to="{name: 'receivables'}" class="emd-text _size_small emd-link">{{$t('viewDetail')}}</router-link>
    </div>
    <div class="emd-container__content">
      <div class="el-section" v-if="isLoaded">
        <div class="el-chunk">
          <div class="eu-flex">
            <div class="eu-flex-item eu-basis_12 eu-basis_6_sm eu-basis_12_lg eu-basis_6_xl eu-border_right_sm eu-no-border_lg eu-border_right_xl">
              <p class="emd-text _size_page _color_default eu-pt_2 eu-align_center_mobile fs-hide">
                <span class="emd-text _size_section eu-mr-1">R$</span> {{ prepaymentAverage.amount ? prepaymentAverage.amount : '-' }}
              </p>
            </div>
            <div class="eu-flex-item eu-basis_12 eu-basis_6_sm eu-basis_12_lg eu-basis_6_xl">
              <div class="eu-flex eu-align_center eu-align_left_lg eu-align_center_xl eu-mt-4 eu-no-margin_sm eu-mt-4_lg eu-no-margin_xl">
                <div class="eu-flex-item">
                  <p class="emd-text _color_subtle _size_tiny">{{$t('averageRate')}}</p>
                  <span class="emd-text _type_caption">{{ prepaymentAverage.tax ? prepaymentAverage.tax : '-' }}%</span>
                </div>
                <div class="eu-flex-item">
                  <p class="emd-text _color_subtle _size_tiny">{{$t('cost')}}</p>
                  <span class="emd-text _type_caption">{{ prepaymentAverage.deduction ? `-${prepaymentAverage.deduction}` : '-' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-chunk eu-py_5" v-else>
        <emd-loader :type="'_subtle'" class="eu-align_center" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "title": "Antecipação",
    "averageRate": "Taxa média (% a.m.)",
    "cost": "Custo antecipação (R$)",
    "viewDetail": "Ver detalhes"
  },
  "en-US": {
    "title": "Prepayment",
    "averageRate": "Average rate (% mo.)",
    "cost": "Prepayment cost (R$)",
    "viewDetail": "View details"
  }
}
</i18n>

<script>
import { EmdLoader } from 'emerald-vue/src/components'

export default {
  name: 'PartialPrepayment',
  components: {
    EmdLoader
  },
  computed: {
    isLoaded () {
      return !!this.$store.getters.prepaymentDetail
    },
    prepaymentAverage () {
      return this.$store.getters.prepaymentAverage
    }
  }
}
</script>
