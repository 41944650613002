import Vue from 'vue'
import Vuex from 'vuex'
import { identify, log as logFS } from '@fullstory/browser'

import { ProfileCore } from 'profile-ts'
import FinanceCore from 'finance-js'
import CompanyCore from 'company-js'

import roles from './modules/roles'
import receivables from './modules/receivables'
import adjustments from './modules/adjustments'
import sales from './modules/sales'
import merchant from './modules/merchant'
import fees from './modules/fees'
import whitelabel from './modules/whitelabel'

import { catchError } from '@/support/errorLogger.js'

import { version } from '@/../package.json'

const dataCore = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
}

export const ProfileInstance = new ProfileCore(dataCore)
export const FinanceInstance = new FinanceCore(dataCore)
export const CompanyInstance = new CompanyCore(dataCore)

Vue.use(Vuex)

const env = FinanceInstance.envAcronym

export default new Vuex.Store({
  strict: !['production'].includes(process.env.NODE_ENV),
  modules: {
    roles,
    receivables,
    adjustments,
    sales,
    merchant,
    fees,
    whitelabel
  },
  state: {
    pref: ProfileInstance.me.getPreferences(),
    appVersion: version,
    profile: undefined,
    globalLoading: true,
    globalError: false,
    globalErrorCode: '',
    maintenance: false,
    isDelinquent: null,
    hasConciliation: true,
    merchantsCount: null
  },
  getters: {
    pref (state) {
      return state.pref
    },
    profile (state) {
      return state.profile
    },
    isEmployee (state) {
      return state?.profile?.user?.is_root || state?.profile?.user?.is_employee
    },
    merchant (state) {
      if (state.profile) {
        return {
          id: state.profile.merchant.id,
          type: state.profile.merchant.type,
          name: state.profile.merchant.name
        }
      } else {
        return {}
      }
    },
    merchantDelinquent: state => {
      return state.isDelinquent
    },
    userType (state) {
      if (state?.profile?.user?.is_employee) {
        return state.profile.user.employee_type
      } else if (state?.profile?.user?.is_root) {
        return { key: 'root', value: 'root' }
      } else if (state.profile) {
        return state.profile.merchant.type
      }
      return { key: '', value: '' }
    },
    userInitials (state) {
      if (state.profile) return ProfileInstance.support.getInitials(state.profile.user.first_name, state.profile.user.last_name)
    },
    hasConciliation (state) {
      return state.hasConciliation
    },
    merchantsCount (state) {
      return state.merchantsCount
    },
    maintenance (state) {
      return state.maintenance
    }
  },
  mutations: {
    SET_PREF (state, pref) {
      state.pref = Object.assign(state.pref, pref)
    },
    toggleGlobalLoading (state, status) {
      state.globalLoading = status
    },
    toggleGlobalError (state, { status, code }) {
      state.globalError = status
      state.globalErrorCode = code
      if (status && code !== 404 && code !== 403 && ['production'].includes(process.env.VUE_APP_CORE_ENV)) logFS('error', 'Error: redirected to screen 500')
    },
    SET_PROFILE (state, profile) {
      state.profile = profile
    },
    SET_MERCHANT_DELINQUENT (state, isDelinquent) {
      state.isDelinquent = isDelinquent
    },
    SET_HAS_CONCILIATION (state, hasConciliation) {
      state.hasConciliation = hasConciliation
    },
    SET_USER_MERCHANTS (state, value) {
      state.merchantsCount = value
    }
  },
  actions: {
    toggleGlobalLoading ({ commit }, status) {
      commit('toggleGlobalLoading', status)
    },
    clearStorage () {
      sessionStorage.removeItem(`${env}mp_finance`)
      window.location.assign('/')
    },
    GET_PREFERENCES (context) {
      const pref = ProfileInstance.me.getPreferences()
      context.commit('SET_PREF', pref)
      return pref
    },
    SET_LANGUAGE ({ dispatch, state }, lang) {
      const preferences = Object.assign({}, JSON.parse(JSON.stringify(state.pref)))
      preferences.language = lang
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_THEME_MODE ({ dispatch, state }, mode) {
      const preferences = Object.assign({}, JSON.parse(JSON.stringify(state.pref)))
      preferences.appearance.mode = mode
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_PREFERENCES (context, preferences) {
      try {
        preferences.appearance.theme = process.env.VUE_APP_THEME
        ProfileInstance.me.setPreferences(preferences)
        if (context.getters.pref.language !== preferences.language) location.reload()
        context.commit('SET_PREF', preferences)
        return true
      } catch (err) {
        catchError(err, context)
      }
    },
    FORCE_LIGHT_MODE ({ state, dispatch }) {
      const preferences = Object.assign({}, JSON.parse(JSON.stringify(state.pref)))
      preferences.appearance.mode = 'light'
      dispatch('SET_PREFERENCES', preferences)
    },
    GET_AUTHORIZATION ({ commit, dispatch }, params = {}) {
      return ProfileInstance.authorization.get(params, 'finance', process.env.VUE_APP_PROFILE_URL)
        .then(res => {
          commit('SET_PROFILE', res.data)
          commit('toggleGlobalLoading', false)
          commit('SET_MERCHANT_DELINQUENT', res.data.merchant.is_delinquent)
          commit('SET_USER_MERCHANTS', res.data.user.merchants_count)

          if (window.newrelic) {
            window.newrelic.interaction()
              .setAttribute('id', res.data.user.id)
              .setAttribute('username', `${res.data.user.first_name} ${res.data.user.last_name}`)
              .setAttribute('email', res.data.user.email)
              .setAttribute('merchant_id', res.data.merchant ? res.data.merchant.id : null)
          }

          const userVars = {
            id: res.data.user.id,
            displayName: `${res.data.user.first_name} ${res.data.user.last_name}`,
            email: res.data.user.email,
            merchantId_str: res.data.merchant ? res.data.merchant.id : null,
            merchantName_str: res.data.merchant ? res.data.merchant.name : null,
            userType_str: res.data.merchant ? res.data.merchant.type.key : null,
            appVersion_str: version
          }

          if (['production', 'contingency'].includes(process.env.VUE_APP_CORE_ENV)) identify(res.data.user.id, userVars)

          return Promise.resolve(res.data)
        })
        .catch(err => {
          commit('toggleGlobalLoading', false)
          catchError(err, { commit, dispatch })
        })
    },
    GET_CONCILIATION_AVAILABILITY ({ commit, dispatch }, merchantId) {
      return FinanceInstance.merchant.getConciliationAvailability(merchantId)
        .then(() => commit('SET_HAS_CONCILIATION', true))
        .catch((err) => {
          commit('SET_HAS_CONCILIATION', false)
          if (!err.status || err.status === 403 || err.status === 404 || err.status === 500) {
            return catchError(err, { commit, dispatch })
          }
          return Promise.reject(err)
        })
    },
    DELETE_AUTHORIZATION (context, logout = false) {
      return ProfileInstance.authorization.deleteCookies('finance', logout)
    }
  }
})
