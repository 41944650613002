import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import App from './App.vue'
import Home from './views/Home.vue'
import Summary from './views/Summary/Summary.vue'
import Maintenance from '@/components/project/globals/Maintenance.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: '_active',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:merchant_id',
      component: Home,
      beforeEnter: async (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
        } else {
          var { merchant_id } = to.params
          const params = { merchant_id }
          if (merchant_id.includes('merch_')) {
            store.dispatch('GET_MERCHANT_SUMMARY', merchant_id)
            store.dispatch('GET_AUTHORIZATION', params).then((res) => {
              if (res.merchant && store.getters.hasConciliation) next()
            })
          } else {
            next({ name: 'default' })
          }
        }
      },
      children: [
        {
          path: '',
          redirect: { name: 'summary' }
        },
        {
          path: 'summary',
          name: 'summary',
          component: Summary,
          beforeEnter: async (to, from, next) => {
            if (store.getters.permissionIsEnabled('finance:view')) {
              const merchantId = store.getters.merchant.id
              store.dispatch('GET_PREPAYMENT', { merchantId, query: {} })
              store.dispatch('GET_PAYOUT_SUMMARY', { merchantId, query: {} })
              store.dispatch('GET_CHARGEBACK', { merchantId, query: {} })
              store.dispatch('GET_CANCELLATION', { merchantId, query: {} })
              store.dispatch('GET_SALES_SUMMARY', { merchantId, query: {} })
              store.dispatch('GET_SALES_SERIES', { merchantId, query: {} })
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          }
        },
        {
          path: 'receivables',
          name: 'receivables',
          beforeEnter: async (to, from, next) => {
            if (store.getters.permissionIsEnabled('finance:view')) {
              const merchantId = store.getters.merchant.id
              const prepaymentQuery = to.query.dprepayment ? { date: to.query.dprepayment } : { }
              const payoutQuery = to.query.dpayout ? { date: to.query.dpayout } : { }
              store.dispatch('GET_PREPAYMENT', { merchantId, query: prepaymentQuery })
              store.dispatch('GET_PAYOUT_SCHEDULE', { merchantId, query: payoutQuery })
              store.dispatch('GET_PAYOUT_DETAIL', { merchantId, query: payoutQuery })
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          },
          component: () => import(/* webpackChunkName: "receivables" */ '@/views/Receivables/Receivables.vue')
        },
        {
          path: 'adjustments',
          name: 'adjustments',
          beforeEnter: async (to, from, next) => {
            if (store.getters.permissionIsEnabled('finance:view')) {
              const merchantId = store.getters.merchant.id
              const chargebackQuery = to.query.dchargeback ? { date: to.query.dchargeback } : { }
              const cancellationQuery = to.query.dcancellation ? { date: to.query.dcancellation } : { }
              store.dispatch('GET_CHARGEBACK', { merchantId, query: chargebackQuery })
              store.dispatch('GET_CANCELLATION', { merchantId, query: cancellationQuery })
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          },
          component: () => import(/* webpackChunkName: "adjustments" */ '@/views/Adjustments/Adjustments.vue')
        },
        {
          path: 'sales',
          name: 'sales',
          beforeEnter: async (to, from, next) => {
            if (store.getters.permissionIsEnabled('finance:view')) {
              const merchantId = store.getters.merchant.id
              const salesQuery = to.query.dsales ? { date: to.query.dsales } : { }
              store.dispatch('GET_SALES_SUMMARY', { merchantId, query: salesQuery })
              store.dispatch('GET_SALES_SERIES', { merchantId, query: salesQuery })
              store.dispatch('GET_SALES_DETAIL', { merchantId, query: salesQuery })
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          },
          component: () => import(/* webpackChunkName: "sales" */ '@/views/Sales/Sales.vue')
        },
        {
          path: 'fees',
          name: 'fees',
          beforeEnter: async (to, from, next) => {
            if (store.getters.permissionIsEnabled('finance:view')) {
              const merchantId = store.getters.merchant.id
              const auditsQuery = to.query.daudits ? { date: to.query.daudits } : { }
              const bestFeesQuery = to.query.dbestFees ? { period: to.query.dbestFees } : { }
              store.dispatch('GET_AUDITS', { merchantId, query: auditsQuery })
              store.dispatch('GET_FEES', { merchantId })
              store.dispatch('GET_BESTFEES', { merchantId, query: bestFeesQuery })
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          },
          component: () => import(/* webpackChunkName: "fees" */ '@/views/Fees/Fees.vue')
        }
      ]
    },
    {
      path: '/',
      name: 'default',
      component: App,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
        } else {
          store.dispatch('GET_AUTHORIZATION', {}).then((res) => {
            if (res.merchant) {
              const merchantId = res.merchant.id
              store.dispatch('GET_MERCHANT_SUMMARY', res.merchant.id).then(() => next({ path: `/${merchantId}/` }))
            }
          })
        }
      }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          store.commit('toggleGlobalLoading', false)
          next()
        } else {
          next({ name: 'default' })
        }
      }
    },
    {
      path: '*',
      redirect: { name: 'default' }
    }
  ]
})
