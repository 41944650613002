export const cashFlowColors = {
  light: {
    gridLinesColor: '#dfe9f0',
    fontColor: '#6F8796',
    bgColor: {
      past: '#26964B',
      next: '#FFCC32'
    },
    hoverBgColor: {
      past: '#31CD65',
      next: '#614800'
    }
  },
  dark: {
    gridLinesColor: '#314C5E',
    fontColor: '#92A6B3',
    bgColor: {
      past: '#ACE6BF',
      next: '#D6AB28'
    },
    hoverBgColor: {
      past: '#29D663',
      next: '#FFEFBF'
    }
  }
}

export const salesSeriesColors = {
  light: {
    gridLinesColor: '#dfe9f0',
    fontColor: '#6F8796',
    bgColor: '#082558',
    hoverBgColor: '#061d46'
  },
  dark: {
    gridLinesColor: '#314C5E',
    fontColor: '#92A6B3',
    bgColor: '#d3d7de',
    hoverBgColor: '#f0f1f4'
  }
}
