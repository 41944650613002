import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import VueAnalytics from 'vue-analytics'
import getGoogleAnalyticsIds from './utils/googleAnalytics'
import { init as initFS } from '@fullstory/browser'

// FONT AWESOME CONFIG & IMPORTS
import './plugins/click-outside'

import 'emerald-workbench/dist/emerald.pagarme.min.css'
if (['jpmorgan', 'pagarme'].includes(process.env.VUE_APP_THEME)) {
  store.dispatch('loadEmeraldByTheme', process.env.VUE_APP_THEME)
}

Vue.config.productionTip = false

if (['production'].includes(process.env.VUE_APP_CORE_ENV)) {
  initFS({ orgId: 'BKP05' })

  const ids = getGoogleAnalyticsIds()
  ids.length &&
  Vue.use(VueAnalytics, {
    id: ids,
    router,
    debug: {
      enabled: false, // Turn on for activate debuging
      sendHitTask: process.env.VUE_APP_CORE_ENV
    }
  })
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
