<template>
  <chart-bar :chart-data="data" :chart-options="options" style="height: 300px;" />
</template>

<i18n>
{
  "pt-BR": {
    "sold": "Vendido",
    "received": "Recebido",
    "scheduled": "Previsto"
  },
  "en-US": {
    "sold": "Sold",
    "received": "Received",
    "scheduled": "Scheduled"
  }
}
</i18n>

<script>
import ChartBar from './../_graphHelpers/bar.js'
import { salesSeriesColors } from '@/support/graphColor'

export default {
  name: 'ChartSalesSeries',
  components: {
    ChartBar
  },
  props: {
    chartData: {
      type: [Array, Boolean],
      default: null
    }
  },
  data () {
    return {
      data: {
        labels: [],
        datasets: [{
          labels: [],
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: []
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              color: salesSeriesColors[this.themeMode || 'light'].gridLinesColor
            },
            ticks: {
              fontColor: salesSeriesColors[this.themeMode || 'light'].fontColor
            }
          }],
          yAxes: [{
            gridLines: {
              color: salesSeriesColors[this.themeMode || 'light'].gridLinesColor
            },
            ticks: {
              beginAtZero: true,
              fontColor: salesSeriesColors[this.themeMode || 'light'].fontColor,
              callback: (label, index, labels) => {
                return label.toLocaleString(this.language, { minimumFractionDigits: 0 })
              }
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              var label = data.datasets[tooltipItem.datasetIndex].labels[tooltipItem.index]
              var value = data.datasets[tooltipItem.datasetIndex].dataLabel[tooltipItem.index]
              return `${this.$t(label)}: R$ ${value}`
            }
          }
        }
      }
    }
  },
  computed: {
    today () {
      const now = new Date()
      now.setHours(0, 0, 0, 0)
      return now.getTime()
    },
    language () {
      return this.$i18n.locale
    },
    themeMode () {
      return this.$store.getters.pref ? this.$store.getters.pref.appearance.mode : 'light'
    }
  },
  created () {
    if (this.chartData) {
      this.updateData()
    }
    this.updateOptions()
  },
  watch: {
    themeMode (newValue, oldValue) {
      this.updateData()
      this.updateOptions()
    },
    chartData (newValue, oldValue) {
      if (newValue) this.updateData()
    }
  },
  methods: {
    updateData () {
      const dates = this.chartData ? this.chartData.map(x => x.date) : []
      const data = this.chartData ? this.chartData.map(x => x.amount.key) : []
      const dataLabel = this.chartData ? this.chartData.map(x => x.amount.value) : []
      const backgroundColor = this.setDataColor(dates, 'bgColor')
      const hoverBackgroundColor = this.setDataColor(dates, 'hoverBgColor')
      const tooltipLabels = this.setTooltipLabels(dates)
      const labels = this.setLabels(dates)
      this.data = Object.assign({},
        {
          labels: labels,
          datasets: [
            {
              labels: tooltipLabels,
              data: data,
              dataLabel: dataLabel,
              backgroundColor: backgroundColor,
              hoverBackgroundColor: hoverBackgroundColor
            }
          ]
        }
      )
    },
    updateOptions () {
      Object.keys(this.options.scales).forEach(key => {
        this.options.scales[key][0] = Object.assign({}, this.options.scales[key][0],
          {
            gridLines: {
              color: salesSeriesColors[this.themeMode].gridLinesColor
            },
            ticks: {
              fontColor: salesSeriesColors[this.themeMode].fontColor
            }
          }
        )
      })
    },
    setDataColor (dates, key) {
      return dates.map((item, index) => salesSeriesColors[this.themeMode][key])
    },
    convertToDate (date) {
      const parts = date.split('-')
      return new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]))
    },
    setLabels (dates) {
      return dates.map((item, index) => {
        const parts = item.split('-')
        return `${parts[2]}`
      })
    },
    setTooltipLabels (dates) {
      return dates.map((item, index) => 'sold')
    }
  }
}
</script>
