<template>
  <div :class="[`emd-${type}`, type === 'container' ? '_type_card' : '']">
    <template v-if="status">
      <emd-status-bar :status="status" />
    </template>
    <div :class="`emd-${type}__content`">
      <div class="eu-flex eu-align_items_center eu-column_sm">
        <template v-if="status">
          <div class="eu-flex-item">
            <emd-badge :status="status" :icon="icon" :text="badge" />
          </div>
        </template>
        <div class="eu-flex-item eu-align_right eu-align_left_sm">
          <div class="eu-pt_4_sm">
            <p class="emd-text eu-display_inline-block_mobile fs-hide" :class="{'_size_page _color_default': !isSubtle, '_size_subpage _color_subtle': isSubtle}">
              <slot name="content">
                <emd-loader :type="'_subtle'" />
              </slot>
            </p>
          </div>
        </div>
      </div>
    </div>
    <template v-if="this.$slots.footer">
      <hr class="emd-divider _weight_light" />
      <p class="eu-px_3 eu-py_1 emd-text _size_tiny _color_nonessential _type_hint">
        <slot name="footer"></slot>
      </p>
    </template>
  </div>
</template>

<script>
import { EmdLoader } from 'emerald-vue/src/components'
import EmdBadge from '@/components/emerald/atoms/EmdBadge.vue'
import EmdStatusBar from '@/components/emerald/atoms/EmdStatusBar.vue'

export default {
  name: 'AppCardEntity',
  components: {
    EmdLoader,
    EmdBadge,
    EmdStatusBar
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'card',
      validator: function (value) {
        return ['card', 'box', 'container'].indexOf(value) !== -1
      }
    },
    status: {
      type: String,
      required: false,
      default: null,
      validator: function (value) {
        return ['success', 'warning', 'danger', 'info', 'primary', 'secondary', 'tertiary', 'link', 'subtle', ''].indexOf(value) !== -1
      }
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    badge: {
      type: String,
      required: true,
      default: null
    },
    isSubtle: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
