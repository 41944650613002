<template>
  <component :is="tag" :data-tippy-content="content" :data-tippy-placement="position" :class="`${className} _${id}`">
    <slot />
    <emd-tippy-template v-if="type === 'template'" :id="id" :title="title" :description="description" />
  </component>
</template>
<script>
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import EmdTippyTemplate from './EmdTippyTemplate.vue'

export default {
  name: 'EmdTippy',
  components: {
    EmdTippyTemplate
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: String,
    description: String,
    type: String,
    content: String,
    position: {
      type: String,
      default: null
    },
    isHidden: {
      type: Boolean,
      required: false,
      default: false
    },
    destroy: {
      type: Boolean,
      required: false,
      default: false
    },
    tag: {
      type: String,
      default: 'span'
    }
  },
  data () {
    return {
      instance: null,
      hasCloseListener: false,
      classes: {
        simple: 'emd-tippy',
        alt: 'emd-tippy-alt',
        ghost: 'emd-tippy-ghost',
        'action-button': 'emd-tippy-action-button',
        template: 'emd-tippy-template'
      }
    }
  },
  computed: {
    className () {
      return this.classes[this.type]
    }
  },
  watch: {
    isHidden (value) {
      if (this.instance) {
        if (value) {
          this.instance.hide()
        } else {
          this.instance.show()
        }
      }
    },
    destroy (value) {
      if (this.instance) {
        if (value) {
          this.instance.destroy()
        }
      }
    }
  },
  mounted () {
    const emdTippy = this
    switch (this.type) {
      case 'simple':
        tippy(`.emd-tippy._${emdTippy.id}`, { // simple
          theme: 'emd-dark',
          placement: this.position || 'bottom'
        })
        break
      case 'alt':
        tippy(`.emd-tippy-alt._${emdTippy.id}`, { // alt
          theme: 'emd-alt',
          interactive: true
        })
        break
      case 'ghost':
        tippy(`.emd-tippy-ghost._${emdTippy.id}`, { // ghost
          theme: 'emd-ghost',
          placement: this.position || 'bottom',
          arrow: false,
          interactive: true,
          allowHTML: true,
          trigger: 'mouseenter focus'
          // content: this.title
        })
        break
      case 'action-button':
        tippy(`.emd-tippy-action-button._${emdTippy.id}`, { // action-button
          theme: 'emd-ghost',
          placement: this.position || 'bottom',
          showOnCreate: false,
          arrow: false,
          interactive: true,
          allowHTML: true
        })
        break
      case 'template':
        const tip = document.querySelectorAll(`[data-template=tooltip-${emdTippy.id}]`) // eslint-disable-line
        // eslint-disable-next-line
        tippy(tip, {
          content (reference) {
            const id = reference.getAttribute('data-template')
            const template = document.getElementById(id)
            if (template) {
              return template.innerHTML
            }
          },
          theme: 'emd-alt',
          allowHTML: true,
          placement: emdTippy.position || 'auto',
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          hideOnClick: false,
          maxWidth: 250,
          onCreate (instance) {
            emdTippy.instance = instance
          },
          onMount (instance) {
            const template = document.getElementById(instance.popper.id)
            const closeBtn = template ? template.querySelector('#close-tooltip') : null
            if (closeBtn && !emdTippy.hasCloseListener) {
              // prevent multiple listeners
              emdTippy.hasCloseListener = true
              closeBtn.addEventListener('click', (e) => {
                emdTippy.instance = null
                emdTippy.$emit('destroy')
                instance.destroy()
              })
            }
          },
          onHidden (instance) {},
          onShow (instance) {
            emdTippy.instance = instance
            if (emdTippy.isHidden) {
              return false
            }
          },
          onDestroy (instance) {
            emdTippy.instance = null
          }
        })
        break
    }
  }
}
</script>

<style>
.emd-tippy-ghost {
  cursor: pointer !important;
}
.emd-tippy-ghost._no-wrap + div[data-tippy-root] {
  white-space: nowrap;
}
</style>
