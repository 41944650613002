<template>
  <div v-if="!isIE" id="app">
    <main class="el-core">
      <div class="el-core__header" v-if="!blockAccess && !isLoading && !hasError && !maintenance">
        <app-core-header @appMenuClicked="toggleAppMenu" @drawerMenuClicked="toggleDrawerMenu" :drawerOpen="drawerMenuOpen" :isOpen="appMenuOpen" />
        <div v-if="merchantDelinquent">
          <div class="emd-status-bar _color_danger">
            <span class="emd-badge"> {{$t('blocked')}}</span>
          </div>
        </div>
      </div>
      <div class="el-core__wrapper" :class="{ '_has_communication': showCommunication }">
        <div class="el-main">
          <div class="el-main__content">
            <div class="el-bounds eu-mt_5_md eu-mt_3">
              <unavailable v-if="!isLoading && !hasConciliation && !hasError" />
              <delinquent v-if="blockAccess" />
              <div class="el-section el-centered eu-align-items_center" v-if="isLoading && !hasError && !blockAccess">
                <emd-pagarme-loader :color="'secondary'" class="fade-in"/>
              </div>
              <global-error v-if="!isLoading && hasError && !blockAccess" :theme="theme" :showLogo="componentIsEnabled('globalError', 'logo')" :code="code" />
              <template v-else-if="!isLoading && !hasError && !blockAccess && hasConciliation">
                <transition
                  name="fade"
                  mode="out-in">
                  <router-view />
                </transition>
              </template>
              <section class="el-section" v-if="!isLoading && !hasError">
                <transition name="fade" mode="out-in">
                  <emd-footer />
                </transition>
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { GlobalError, EmdPagarmeLoader } from 'emerald-vue/src/components'
import Delinquent from './components/project/globals/Delinquent'
import Unavailable from './components/project/globals/Unavailable'
import EmdFooter from './components/emerald/organisms/EmdFooter'
import AppCoreHeader from './components/project/ui/header/AppCoreHeader.vue'

export default {
  name: 'App',
  components: {
    GlobalError,
    EmdPagarmeLoader,
    Delinquent,
    Unavailable,
    EmdFooter,
    AppCoreHeader
  },
  data () {
    return {
      appMenuOpen: false,
      drawerMenuOpen: false
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.globalLoading
    },
    hasError () {
      return this.$store.state.globalError
    },
    appVersion () {
      return this.$store.state.appVersion
    },
    merchantDelinquent () {
      return this.$store.getters.merchantDelinquent
    },
    userType () {
      return this.$store.getters.userType.key
    },
    isEmployee () {
      return this.$store.getters.isEmployee
    },
    blockAccess () {
      return this.merchantDelinquent && !this.isEmployee
    },
    hasConciliation () {
      return this.$store.getters.hasConciliation
    },
    merchant () {
      return this.$store.getters.merchant
    },
    isIE () {
      return window.isIE
    },
    code () {
      return this.$store.state.globalErrorCode || 500
    },
    theme () {
      return process.env.VUE_APP_THEME
    },
    maintenance () {
      return this.$store.getters.maintenance
    }
  },
  methods: {
    toggleAppMenu () {
      this.appMenuOpen = !this.appMenuOpen
      if (this.drawerMenuOpen) this.drawerMenuOpen = false
    },
    toggleDrawerMenu () {
      this.drawerMenuOpen = !this.drawerMenuOpen
      if (this.appMenuOpen) this.appMenuOpen = false
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  },
  mounted () {
    var meta = document.createElement('meta')
    meta.name = 'version'
    meta.content = this.appVersion
    document.getElementsByTagName('head')[0].appendChild(meta)
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "blocked": "Bloqueado"
  },
  "en-US": {
    "blocked": "Blocked"
  }
}
</i18n>

<style>
#atlwdg-trigger {
  background: var(--brand--le--primary-color--base);
  color: #fff !important;
  border: none;
  box-shadow: none;
  border-radius: 4px 0 0 0;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 11px;
  padding: 6px 12px;
}
</style>
