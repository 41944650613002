const whitelabel = {
  state: {
    whitelabel: {
      enabled: false,
      theme: 'pagarme',
      url: process.env.VUE_APP_BASE_URL,
      domain: '.pagar.me'
    }
  },
  getters: {
    whitelabel (state) {
      return state.whitelabel
    }
  },
  mutations: {
    updateWhitelabel (state, value) {
      state.whitelabel = value
    }
  },
  actions: {
    updateWhitelabel ({ commit }, value) {
      commit('updateWhitelabel', value)
    },
    loadEmeraldByTheme ({ dispatch, commit }, theme) {
      const skin = ['pagarme'].includes(theme) ? 'pagarme' : theme
      const emerald = () => import(`emerald-workbench/dist/emerald.${skin}.min.css`)
      commit('updateWhitelabel', { theme: theme })
      if (['pagarme'].includes(theme)) dispatch('FORCE_LIGHT_MODE')
      emerald()
    }
  }
}
export default whitelabel
