<template>
  <div
    class="emd-status-bar"
    :class="status != '' ? `_color_${status}` : ''">
  </div>
</template>

<script>
export default {
  name: 'EmdStatusBar',
  props: {
    status: {
      type: String,
      required: true,
      validator: function (value) {
        return ['success', 'warning', 'danger', 'info', 'primary', 'secondary', 'tertiary', 'link', 'subtle', ''].indexOf(value) !== -1
      }
    }
  }
}
</script>
