<template>
  <div class="eu-flex-item eu-basis_12 eu-basis_6_lg eu-mb_5">
    <div class="emd-container _highlight_header">
      <div class="emd-container__header">
        <h2 class="emd-text _size_section">{{title}}</h2>
        <router-link :to="{name: 'adjustments'}" class="emd-text _size_small emd-link">{{$t('viewDetail')}}</router-link>
      </div>
      <div class="emd-container__content">
        <div class="el-section" v-if="data">
          <div class="eu-flex eu-align_items_center eu-column eu-row_sm eu-column_lg">
            <div class="eu-flex-item eu-border_right_sm eu-no-border_lg">
              <div class="el-chunk">
                <p class="emd-text _size_page _color_default eu-pt_2 eu-align_center_mobile fs-hide">
                  <span class="emd-text _size_section eu-mr-1">R$</span> {{ data.total_amount ? data.total_amount.value : '-' }}
                </p>
              </div>
            </div>
            <div class="eu-flex-item">
              <div class="el-chunk">
                <div class="eu-flex eu-justify_space-between eu-align_center eu-align_left_lg">
                  <div class="eu-flex-item">
                    <p class="emd-text _color_subtle _size_tiny">{{$t('quantity')}}</p>
                  </div>
                  <div class="eu-flex-item eu-align_right_lg">
                    <span class="emd-text _type_caption fs-hide">{{data.total_quantity}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="el-chunk eu-py_5" v-else>
          <emd-loader :type="'_subtle'" class="eu-align_center" />
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "viewDetail": "Ver detalhes",
    "quantity": "Quantidade"
  },
  "en-US": {
    "viewDetail": "View details",
    "quantity": "Quantity"
  }
}
</i18n>

<script>
import { EmdLoader } from 'emerald-vue/src/components'

export default {
  name: 'PartialAdjustments',
  components: {
    EmdLoader
  },
  props: {
    title: {
      type: String,
      default: null
    },
    data: {
      type: [Object, Boolean],
      default: false
    }
  },
  computed: {
    totalLabel () {
      return this.$i18n.locale === 'pt-BR' ? `Total de ${this.title}` : `${this.title} total`
    },
    isLoaded () {
      return !!this.data
    }
  },
  methods: {
    goToMonth (date) {
      this.$emit('month-changed', date)
    }
  }
}
</script>
