<template>
  <div class="eu-display_none" :id="`tooltip-${id}`" style="position: absolute;">
    <div class="eu-display_flex eu-justify_space-between eu-mb_2">
      <h3 v-if="title" class="emd-text _color_primary">{{ title }}</h3>
      <a href="javascript:void(0)" class="emd-link _color_subtle" id="close-tooltip">
        <i class="uil uil-times" />
      </a>
    </div>
    <p v-if="description" class="eu-mt_1">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'EmdTippyTemplate',
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
