<template>
  <div class="eu-flex-item eu-basis_12 eu-basis_5_lg">
    <nav class="emd-segmented-controls" >
      <button class="emd-segmented-controls__item" :class="{'_active': activeSegment === 'acquirer'}" @click="changeSegment('acquirer')">
        {{$t('acquirer')}}
      </button>
      <button class="emd-segmented-controls__item" :class="{'_active': activeSegment === 'network'}" @click="changeSegment('network')">
        {{$t('network')}}
      </button>
      <button class="emd-segmented-controls__item" :class="{'_active': activeSegment === 'bank'}" @click="changeSegment('bank')">
        {{$t('bank')}}
      </button>
    </nav>
    <div class="el-chunk eu-py_5" v-if="!payoutSummaryIsLoaded">
      <emd-loader :type="'_subtle'" class="eu-align_center" />
    </div>
    <div v-else>
      <acquirer-detail :chartData="payoutSummaryAcquirer" v-if="activeSegment === 'acquirer'"></acquirer-detail>
      <network-detail :chartData="payoutSummaryNetwork"  v-if="activeSegment === 'network'"></network-detail>
      <bank-detail :chartData="payoutSummaryBank" v-if="activeSegment === 'bank'"></bank-detail>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "acquirer": "Adquirente",
    "network": "Bandeira",
    "bank": "Banco"
  },
  "en-US": {
    "acquirer": "Acquirer",
    "network": "Brand",
    "bank": "Bank"
  }
}
</i18n>

<script>
import ChartStatsLegendDetail from '../_graphHelpers/ChartStatsLegendDetail'
import { EmdLoader } from 'emerald-vue/src/components'

export default {
  name: 'PartialReceivablesCharts',
  components: {
    EmdLoader,
    AcquirerDetail: ChartStatsLegendDetail,
    BankDetail: ChartStatsLegendDetail,
    NetworkDetail: ChartStatsLegendDetail
  },
  data () {
    return {
      activeSegment: 'acquirer'
    }
  },
  computed: {
    payoutSummaryIsLoaded () {
      return this.$store.getters.payoutSummaryIsLoaded
    },
    payoutSummaryAcquirer () {
      return this.$store.getters.payoutSummaryAcquirer
    },
    payoutSummaryNetwork () {
      return this.$store.getters.payoutSummaryNetwork
    },
    payoutSummaryBank () {
      return this.$store.getters.payoutSummaryBank
    }
  },
  methods: {
    changeSegment (segment) {
      this.activeSegment = segment
    }
  }
}
</script>
