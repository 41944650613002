import { FinanceInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

const receivables = {
  state: {
    prepayment: null,
    payoutSchedule: null,
    payoutDetail: null,
    payoutSummary: null,
    payoutSummaryDays: null,
    acquirerColors: {
      Cielo: '#7BD6FB',
      Rede: '#FFBD7A',
      Sodexo: '#434D9E',
      Ticket: '#ED6161',
      Stone: '#B1DE8F',
      'VR Benefícios': '#CDAA62'
    },
    bankColors: {
      1: '#F2D91C',
      341: '#C56805',
      104: '#388BC6',
      41: '#83B9E5',
      33: '#E82B2D',
      0: '#ACACAC',
      'banco do brasil': '#F2D91C',
      itaú: '#C56805',
      cef: '#388BC6',
      banrisul: '#83B9E5',
      santander: '#E82B2D',
      outros: '#ACACAC'
    },
    networkColors: {
      MasterCard: '#F7B93A',
      Visa: '#808AC2',
      Elo: '#75D0F5',
      Sodexo: '#434D9E',
      AMEX: '#77ACCD',
      Ticket: '#ED6161',
      'VR Benefícios': '#CDAA62',
      Hipercard: '#D68081',
      JCB: '#B55162',
      Sorocred: '#A09E4B',
      Mais: '#BC90CA',
      Outras: '#A3A3A3'
    }
  },
  getters: {
    payoutIsLoaded: state => {
      return !!state.payoutSchedule
    },
    payoutDetailsIsLoaded: state => {
      return !!state.payoutDetail
    },
    payoutSummaryIsLoaded: state => {
      return !!state.payoutSummary
    },
    payoutScheduleCursor: state => {
      return state.payoutSchedule ? state.payoutSchedule.cursor : false
    },
    payoutSchedule: state => {
      return state.payoutSchedule ? state.payoutSchedule : false
    },
    prepaymentCursor: state => {
      return state.prepayment ? state.prepayment.cursor : false
    },
    prepaymentAverage: state => {
      if (state.prepayment) {
        return {
          amount: state.prepayment.total_amount,
          deduction: state.prepayment.total_deduction,
          tax: state.prepayment.average_tax
        }
      } else {
        return false
      }
    },
    prepaymentDetail: state => {
      // acquirer Data
      if (state.prepayment) {
        return state.prepayment.details.map((detail, index) => {
          return {
            id: `${detail.entity.key}-${index}`,
            tagColor: detail.color,
            header: {
              label: detail.entity.value,
              amount: {
                value: detail.amount
              }
            },
            table: {
              label: 'averageRate',
              value: 'anticipationCost',
              items: [
                {
                  label: `${detail.tax}%`,
                  value: `-${detail.deduction}`
                }
              ]
            }
          }
        })
      } else {
        return false
      }
    },
    payoutDetailAcquirer: state => {
      if (state.payoutDetail) {
        return state.payoutDetail.acquirer.map((detail, index) => {
          return {
            id: `${detail.entity.key}-${index}`,
            tagColor: detail.color,
            header: {
              label: detail.entity.value,
              amount: {
                key: detail.total_amount.key,
                value: detail.total_amount.value
              }
            }
          }
        })
      } else {
        return false
      }
    },
    payoutDetailNetwork: state => {
      if (state.payoutDetail) {
        return state.payoutDetail.network.map((detail, index) => {
          return {
            id: `${detail.entity.key}-${index}`,
            tagColor: detail.color,
            header: {
              label: detail.entity.value,
              amount: {
                key: detail.total_amount.key,
                value: detail.total_amount.value
              }
            }
          }
        })
      } else {
        return false
      }
    },
    payoutDetailBank: state => {
      if (state.payoutDetail) {
        return state.payoutDetail.bank.map((detail, index) => {
          return {
            id: `${detail.entity.key}-${index}`,
            tagColor: detail.color,
            header: {
              label: detail.entity.value,
              sublabel: `AG ${detail.agency} | CC ${detail.account}`,
              amount: {
                key: detail.total_amount.key,
                value: detail.total_amount.value
              }
            }
          }
        })
      } else {
        return false
      }
    },
    payoutSummaryAcquirer: state => {
      if (state.payoutSummary) {
        return state.payoutSummary.acquirer.map((detail, index) => {
          return {
            id: `${detail.entity.key}-${index}`,
            tagColor: state.acquirerColors[detail.entity.key],
            header: {
              label: detail.entity.value,
              amount: {
                key: detail.total_amount.key,
                value: detail.total_amount.value
              }
            }
          }
        })
      } else {
        return false
      }
    },
    payoutSummaryNetwork: state => {
      if (state.payoutSummary) {
        return state.payoutSummary.network.map((detail, index) => {
          return {
            id: `${detail.entity.key}-${index}`,
            tagColor: state.networkColors[detail.entity.key],
            header: {
              label: detail.entity.value,
              amount: {
                key: detail.total_amount.key,
                value: detail.total_amount.value
              }
            }
          }
        })
      } else {
        return false
      }
    },
    payoutSummaryBank: state => {
      if (state.payoutSummary) {
        return state.payoutSummary.bank.map((detail, index) => {
          return {
            id: `${detail.entity.key}-${index}`,
            tagColor: state.bankColors[detail.entity.key.toLowerCase()],
            header: {
              label: detail.entity.value,
              sublabel: `AG ${detail.agency} | CC ${detail.account}`,
              amount: {
                key: detail.total_amount.key,
                value: detail.total_amount.value
              }
            }
          }
        })
      } else {
        return false
      }
    },
    payoutSummaryDays: state => {
      return state.payoutSummaryDays
    }
  },
  mutations: {
    SET_PREPAYMENT (state, prepayment) {
      state.prepayment = prepayment
    },
    SET_PAYOUT_SCHEDULE (state, payoutSchedule) {
      state.payoutSchedule = payoutSchedule
    },
    SET_PAYOUT_DETAIL (state, payoutDetail) {
      state.payoutDetail = payoutDetail
    },
    SET_PAYOUT_SUMMARY (state, payoutSummary) {
      state.payoutSummary = payoutSummary
    },
    SET_PAYOUT_SUMMARY_DAYS (state, payoutSummaryDays) {
      state.payoutSummaryDays = payoutSummaryDays
    }
  },
  actions: {
    GET_PREPAYMENT ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_PREPAYMENT', null)
      return FinanceInstance.prepayment.get(merchantId, query)
        .then(response => {
          commit('SET_PREPAYMENT', response)
        })
        .catch(err => catchError(err, { commit, dispatch }))
    },
    GET_PAYOUT_SCHEDULE ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_PAYOUT_SCHEDULE', null)
      return FinanceInstance.payout.getSchedule(merchantId, query)
        .then(response => {
          commit('SET_PAYOUT_SCHEDULE', response)
        })
        .catch(err => catchError(err, { commit, dispatch }, merchantId, query))
    },
    GET_PAYOUT_DETAIL ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_PAYOUT_DETAIL', null)
      return Promise.all([
        FinanceInstance.payout.get('acquirer', merchantId, query),
        FinanceInstance.payout.get('network', merchantId, query),
        FinanceInstance.payout.get('bank', merchantId, query)
      ])
        .then(([acquirer, network, bank]) => {
          commit('SET_PAYOUT_DETAIL', {
            acquirer: acquirer.items,
            network: network.items,
            bank: bank.items
          })
        })
        .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
    },
    GET_PAYOUT_SUMMARY ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_PAYOUT_SUMMARY', null)
      commit('SET_PAYOUT_SUMMARY_DAYS', null)
      return Promise.all([
        FinanceInstance.payout.getSummary('acquirer', merchantId, query),
        FinanceInstance.payout.getSummary('network', merchantId, query),
        FinanceInstance.payout.getSummary('bank', merchantId, query)
      ])
        .then(([acquirer, network, bank]) => {
          commit('SET_PAYOUT_SUMMARY', {
            acquirer: acquirer.items,
            network: network.items,
            bank: bank.items
          })
          commit('SET_PAYOUT_SUMMARY_DAYS', acquirer.summary)
        })
        .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
    }
  }
}

export default receivables
