import { FinanceInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

const audits = {
  state: {
    audits: null,
    myFees: null,
    bestFees: null
  },
  getters: {
    audits: state => { return state.audits },
    auditsCursor: state => {
      return state.audits ? state.audits.cursor : false
    },
    myFees: state => { return state.myFees },
    bestFees: state => { return state.bestFees }
  },
  mutations: {
    SET_AUDITS (state, audits) {
      state.audits = audits
    },
    SET_MY_FEES (state, myFees) {
      state.myFees = myFees
    },
    SET_BEST_FEES (state, bestFees) {
      state.bestFees = bestFees
    }
  },
  actions: {
    GET_AUDITS ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_AUDITS', null)
      FinanceInstance.fees.getAudits(merchantId, query)
        .then(response => commit('SET_AUDITS', response))
        .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
    },
    GET_FEES ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_MY_FEES', null)
      FinanceInstance.fees.get(merchantId)
        .then(response => commit('SET_MY_FEES', response.fees))
        .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
    },
    /*
      * period query:
      * 2 – Últimos 7 dias
      * 4 – Últimos 30 dias
      * 8 – Ontem
    */
    GET_BESTFEES ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_BEST_FEES', null)
      FinanceInstance.fees.getBest(merchantId, query)
        .then(response => commit('SET_BEST_FEES', response))
        .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
    }
  }
}
export default audits
