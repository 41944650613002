<template>
  <span
    class="emd-badge"
    :class="status != '' ? `_color_${status}` : ''">
    <template v-if="icon">
      <i :class="['uil', `uil-${icon}`, 'eu-mr_1']" />
    </template>
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'EmdBadge',
  props: {
    status: {
      type: String,
      required: true,
      validator: (value) => {
        return ['success', 'warning', 'danger', 'info', 'primary', 'secondary', 'tertiary', 'link', 'subtle', ''].indexOf(value) !== -1
      }
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    text: {
      type: String,
      required: true,
      default: null
    }
  }
}
</script>
