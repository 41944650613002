<template>
  <div class="el-core__header__left" >
    <div class="emd-control" :class="[ {'_open': contextOpen} ]" >
      <div class="emd-control__wrapper">
        <div class="emd-control__link">
          <div class="emd-control__icon">
            <i class="uil uil-store"/>
          </div>
          <a :href="urlCompany" class="emd-control__text" :title="merchant.name">
            <span class="emd-control__text__item" v-if="merchant">{{ merchant.name }}</span>
          </a>
        </div>
        <emd-tippy class="_no-wrap" id="merchant" :type="'ghost'" :position="'left'" :content="$t('tooltip.merchant')" >
          <button class="emd-action-button _has-tooltip _type_ghost" :data-tippy-content="$t('tooltip.merchant')" aria-expanded="false" @click="contextAnchorMerchant">
            <i class="emd-action-button__icon uil uil-exchange" />
          </button>
        </emd-tippy>
      </div>
    </div>
    <div class="eu-ml_4 eu-display_none eu-display_block_md">
      <div class="emd-logo-custom__image"></div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "tooltip": {
      "merchant": "Trocar Empresa"
    }
  },
  "en-US": {
    "tooltip": {
      "merchant": "Trocar Merchant"
    }
  }
}
</i18n>

<script>
import EmdTippy from '@/components/emerald/atoms/EmdTippy'

export default {
  name: 'AppCoreHeaderLeft',
  components: {
    EmdTippy
  },
  props: {
    merchant: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    isOpen: {
      type: Boolean
    }
  },
  data () {
    return {
      contextOpen: false
    }
  },
  computed: {
    merchantId () {
      return this.$store.getters.merchant.id
    },
    isEmployee () {
      return this.$store.getters.isEmployee
    },
    urlCompany () {
      return `${process.env.VUE_APP_COMPANY_URL}/${this.merchantId}`
    }
  },
  methods: {
    contextAnchorMerchant () {
      if (this.isEmployee) {
        window.location.assign(`${process.env.VUE_APP_BACKOFFICE_URL}`)
      } else {
        window.location.assign(`${process.env.VUE_APP_PROFILE_URL}/merchants`)
      }
    },
    toggleAppMenu () {
      this.$emit('appMenuClicked')
    }
  }
}
</script>
