<template>
  <div class="eu-flex-item eu-basis_12 eu-basis_6_lg eu-mb_5">
    <div class="emd-container _highlight_header">
      <div class="emd-container__header">
        <h2 class="emd-text _size_section">{{$t('sales')}}</h2>
        <router-link :to="{name: 'sales'}" class="emd-text _size_small emd-link">{{$t('viewDetail')}}</router-link>
      </div>
      <div class="emd-container__content">
        <div class="el-chunk fs-hide">
          <card-total-sales status="tertiary" icon="check-circle" :badge="$t('totalSales')" type="container">
            <template slot="content" v-if="salesSummary">
              <span class="emd-text _size_section eu-mr-1">R$</span> {{ salesSummary.amount ? salesSummary.amount.value : '-' }}
            </template>
          </card-total-sales>
        </div>
        <div class="el-section">
          <chart-sales-series :chartData="salesSeriesData" />
        </div>
        <div class="eu-flex">
          <div class="eu-flex-item eu-basis_12 eu-basis_4_xl eu-wrap eu-mt_3 eu-no-margin_sm">
            <card-transactions status="primary" icon="schedule" :badge="$t('transactions')" isSubtle type="container">
              <template slot="content" v-if="salesSummary">
                {{ salesSummary.transactions ? salesSummary.transactions.value : '-' }}
              </template>
            </card-transactions>
          </div>
          <div class="eu-flex-item eu-basis_12 eu-basis_4_xl eu-wrap eu-mt_3 eu-no-margin_xl">
            <card-average-ticket status="info" icon="usd-circle" :badge="$t('averageTicket')" isSubtle type="container">
              <template slot="content" v-if="salesSummary">
                <span class="emd-text _size_section eu-mr-1">R$</span> {{ salesSummary.average_ticket ? salesSummary.average_ticket.value : '-' }}
              </template>
            </card-average-ticket>
          </div>
          <div class="eu-flex-item eu-basis_12 eu-basis_4_xl eu-wrap eu-mt_3 eu-no-margin_xl">
            <card-average-installments status="secondary" icon="sync" :badge="$t('averageInstallments')" isSubtle type="container">
              <template slot="content" v-if="salesSummary">
                {{ salesSummary.average_installment ? salesSummary.average_installment.value : '-' }}
              </template>
            </card-average-installments>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "viewDetail": "Ver detalhes",
    "sales": "Vendas",
    "totalSales": "Total de vendas",
    "transactions": "Transações",
    "averageTicket": "Ticket médio",
    "averageInstallments": "Parcelamento médio"
  },
  "en-US": {
    "viewDetail": "View details",
    "sales": "Sales",
    "totalSales": "Total sales",
    "transactions": "Transactions",
    "averageTicket": "Average ticket",
    "averageInstallments": "Average installments"
  }
}
</i18n>

<script>
import AppCardEntity from './../AppCardEntity'
import ChartSalesSeries from '../Sales/ChartSalesSeries'

export default {
  name: 'PartialSales',
  components: {
    ChartSalesSeries,
    CardTotalSales: AppCardEntity,
    CardTransactions: AppCardEntity,
    CardAverageTicket: AppCardEntity,
    CardAverageInstallments: AppCardEntity
  },
  computed: {
    salesSummary () {
      return this.$store.getters.salesSummary
    },
    salesSeriesData () {
      return this.$store.getters.salesSeries
    }
  }
}
</script>
