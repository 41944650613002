const components = {
  logo: {
    pagarme: ['pagarme'],
    jpmorgan: ['jpmorgan']
  },
  global: {
    pix: ['pagarme']
  },
  globalError: {
    logo: ['pagarme']
  },
  footer: {
    textJPMorgan: ['jpmorgan'],
    textPagarme: ['pagarme'],
    switchThemeMode: [],
    status: ['pagarme'],
    help: ['pagarme'],
    documentation: ['pagarme', 'jpmorgan'],
    privacySecurity: ['jpmorgan'],
    urlSite: ['pagarme'],
    switchLanguage: ['pagarme', 'jpmorgan'],
    privacyPolicy: ['pagarme'],
    termsOfUse: ['pagarme'],
    termsOfUseModal: [],
    secureEnv: ['pagarme', 'jpmorgan']
  }
}

export default components
