<template>
  <div class="el-section">
    <emd-tabbar :items="tabbarItems" :activeItem="$router.currentRoute.name" @changed="goToRoute" bordered />
    <router-view />
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "summary": "Consolidado",
    "receivables": "Recebíveis",
    "adjustments": "Ajustes",
    "sales": "Vendas",
    "fees": "Taxas"
  },
  "en-US": {
    "summary": "Summary",
    "receivables": "Receivables",
    "adjustments": "Adjustments",
    "sales": "Sales",
    "fees": "Fees"
  }
}
</i18n>

<script>
import EmdTabbar from '@/components/emerald/molecules/EmdTabbar.vue'

export default {
  name: 'Home',
  components: {
    EmdTabbar
  },
  computed: {
    tabbarItems () {
      var obj = [
        {
          key: 'summary',
          value: this.$t('summary')
        },
        {
          key: 'receivables',
          value: this.$t('receivables')
        },
        {
          key: 'adjustments',
          value: this.$t('adjustments')
        },
        {
          key: 'sales',
          value: this.$t('sales')
        }
      ]
      if (this.permissionIsEnabled('finance:view')) {
        obj.push({ key: 'fees', value: this.$t('fees') })
      }
      return obj
    },
    merchant () {
      return this.$store.getters.merchant
    }
  },
  created () {
    document.title = `${this.merchant.name} | ${this.$t(this.$router.currentRoute.name)} - Mundi Finance`
  },
  watch: {
    '$route' (to, from) {
      this.$nextTick()
        .then(() => {
          document.title = `${this.merchant.name} | ${this.$t(to.name)} - Mundi Finance`
        })
    }
  },
  mounted () {
    const ZP = window.$zopim

    const noMustDisplay = {
      employee: this.$store.getters?.isEmployee,
      forSupportType: this.$store.getters?.merchantSummary?.support_type === 'special',
      forWhiteLabel: this.$store.getters?.merchantSummary?.is_white_label
    }

    if (Object.values(noMustDisplay).includes(true)) {
      ZP(() => ZP.livechat.hideAll())
    } else {
      ZP(() => {
        ZP.livechat.departments.filter(1821107321)
        ZP.livechat.set({
          name: this.$store.getters?.merchantSummary?.name
        })

        ZP.livechat.window.show()
        ZP.livechat.window.toggle()
      })
    }
  },
  methods: {
    goToRoute (route) {
      this.$router.push(route.key)
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  }
}
</script>
