<template>
  <div class="el-core__header__right">
    <div class="emd-drawer _right">
      <div class="emd-drawer__trigger" :class="{ '_open': isOpen }">
        <button class="emd-action-button" @click="toggleDrawerMenu">
          <i class="uil uil-ellipsis-v emd-action-button__icon"></i>
        </button>
      </div>

      <div class="emd-drawer__content" :class="{ '_open': isOpen }">
        <div class="emd-application" v-if="canShowMoreList || canShowAppsList">
          <emd-dropdown>
            <template v-slot:trigger>
              <div class="emd-application__content">
                <!-- ícone de novidade -->
                <!-- <span class="emd-status-tag _color_success">
                  <emeraldraw draw="circle" fixed-width size="xs" transform="width-16 shrink-6 up-1"/>
                </span> -->
               <i class="uil uil-apps emd-application__icon"></i>
                <span class="emd-text emd-text _size_section">
                  Finance
                </span>
                <i class="uil uil-angle-down emd-application__arrow"></i>
              </div>
            </template>
            <template v-slot:content>
              <template v-if="canShowAppsList">
                <div class="emd-text _color_default _type_label _size_tiny eu-ma_2">{{$t('apps-menu.your-apps')}}</div>
                <ul>
                  <li class="emd-dropdown__content__item" v-if="canShowDash">
                    <a class="emd-text _color_default" :href="urlDash" target="_blank" rel="noopener noreferrer" >
                      <p>
                        {{$t('apps-menu.dash')}}
                      </p>
                    </a>
                  </li>
                </ul>
              </template>
              <div v-if="canShowMoreList && canShowAppsList" class="emd-divider _weight_base eu-mt_2 eu-mb_3"></div>
              <template v-if="canShowMoreList">
                <div class="emd-text _color_default _type_label _size_tiny eu-ma_2">{{$t('apps-menu.get-to-know')}}</div>
                <ul>
                  <li class="emd-dropdown__content__item">
                    <a class="emd-text _color_default" target="_blank" rel="noopener noreferrer" :href="pixUrl">
                      <p>
                        {{$t('apps-menu.pix')}}
                      </p>
                    </a>
                  </li>
                </ul>
              </template>
            </template>
          </emd-dropdown>
        </div>
        <!-- implementação da tippy diferentinha -->
        <span>
          <emd-tippy :href="urlCompany" id="company" tag="a" class="emd-action-button _has-tooltip"  :type="'action-button'" :content="$t('company')">
            <i class="uil uil-building emd-action-button__icon"></i>
          </emd-tippy>
        </span>
        <div class="emd-dropdown _position_right jsDropdown" data-onboarding="profile">
          <emd-dropdown :isOpen="profileOpen">
            <template v-slot:trigger>
              <!-- implementação da tippy diferentinha -->
              <span>
                <emd-tippy id="profile" tag="button" class="emd-action-button _has-tooltip"  :type="'action-button'" :content="$t('profile')">
                  <i class="emd-action-button__icon uil uil-user"></i>
                  <i class="emd-action-button__arrow uil uil-angle-down"></i>
                </emd-tippy>
              </span>
            </template>
            <template v-slot:content>
              <div class="emd-dropdown__content__item">
                <span class="emd-badge eu-mt_1">{{ permission }}</span>
                <div class="emd-text _color_default _type_label _size_tiny eu-ma_2">{{$t('profile')}}</div>
              </div>
              <ul>
                <li class="emd-dropdown__content__item">
                  <a class="emd-text _color_default" :href="profileUrl" target="_blank" rel="noopener noreferrer" >
                    <p class="emd-dropdown__content__item__text">
                      <span class="emd-text _color_nonessential">
                        <i class="uil uil-user"></i>
                      </span>
                      <span>
                        {{ firstName }} {{ lastName }}
                      </span>
                    </p>
                  </a>
                </li>
                <li class="emd-dropdown__content__item">
                  <a class="emd-text _color_danger" href="javascript:void(0);" @click="triggerLogout">
                    <p class="emd-dropdown__content__item__text">
                      <i class="uil uil-sign-out-alt"></i>
                      <span>
                        {{$t('logout')}}
                      </span>
                    </p>
                  </a>
                </li>
              </ul>
            </template>
          </emd-dropdown>
        </div>
        <span v-if="isEmployee">
          <emd-tippy
            :href="urlBackoffice"
            id="backoffice" tag="a"
            :class="`emd-action-button _has-tooltip ${componentIsEnabled('logo', 'pagarme') ? 'emd-pagarme-icon-outline' : 'emd-mundi-icon _type_outline'}`"
            :type="'action-button'"
            :content="$t('backoffice')"
          >
            <span>
              <emd-pagarme-icon v-if="componentIsEnabled('logo', 'pagarme')" />
              <emd-mundi-icon v-else />
            </span>
          </emd-tippy>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import EmdTippy from '@/components/emerald/atoms/EmdTippy'
import EmdMundiIcon from '@/components/emerald/atoms/EmdMundiIcon.vue'
import EmdPagarmeIcon from '@/components/emerald/atoms/EmdPagarmeIcon.vue'
import EmdDropdown from '@/components/emerald/molecules/EmdDropdown.vue'

export default {
  name: 'AppCoreHeaderRight',
  components: {
    EmdTippy,
    EmdMundiIcon,
    EmdPagarmeIcon,
    EmdDropdown
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    merchant: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    },
    profile: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      profileOpen: false,
      pixUrl: process.env.VUE_APP_PIX_URL
    }
  },
  computed: {
    isEmployee () {
      return this.$store.getters.isEmployee
    },
    firstName () {
      return this.profile.first_name
    },
    lastName () {
      return this.profile.last_name
    },
    urlCompany () {
      return `${process.env.VUE_APP_COMPANY_URL}/${this.merchant.id}`
    },
    profileUrl () {
      return `${process.env.VUE_APP_PROFILE_URL}`
    },
    urlBackoffice () {
      return `${process.env.VUE_APP_BACKOFFICE_URL}`
    },
    urlDash () {
      return `${process.env.VUE_APP_DASH_URL}/${this.merchant.id}/${this.merchant.account.id}`
    },
    canShowPix () {
      return this.permissionIsEnabled('links.pix:get') && this.componentIsEnabled('global', 'pix')
    },
    canShowDash () {
      return this.permissionIsEnabled('insights:view')
    },
    canShowAppsList () {
      return this.canShowDash
    },
    canShowMoreList () {
      return this.canShowPix
    },
    canShowDashDropDown () {
      return this.canShowAppsList || this.canShowMoreList
    },
    userType () {
      return this.$store.getters.userType
    },
    permission () {
      const type = this.userType.key.toLowerCase()
      if (this.isEmployee || type === 'owner' || type === 'admin') {
        return this.userType.value
      } else {
        return this.userRole.value
      }
    }
  },
  methods: {
    toggleDrawerMenu () {
      this.$emit('drawerMenuClicked')
    },
    triggerLogout () {
      this.$store.dispatch('toggleGlobalLoading', true)
      this.$store.dispatch('DELETE_AUTHORIZATION', true)
      window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "apps-menu": {
      "your-apps": "Seus apps",
      "dash": "Dash",
      "pix": "Pix",
      "get-to-know": "Conheça"
    },
    "logout": "Sair",
    "company": "Company",
    "profile": "Perfil",
    "backoffice": "Backoffice"
  },
  "en-US": {
    "apps-menu": {
      "your-apps": "Your apps",
      "dash": "Dash",
      "pix": "Pix",
      "get-to-know": "Get to know"
    },
    "logout": "Logout",
    "company": "Company",
    "profile": "Profile",
    "backoffice": "Backoffice"
  }
}
</i18n>
