import { CompanyInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

const merchant = {
  state: {
    summary: null
  },
  mutations: {
    'SET_MERCHANT_SUMMARY' (state, payload) {
      state.summary = payload
    }
  },
  getters: {
    merchantSummary: state => {
      return state.summary
    }
  },
  actions: {
    'SET_MERCHANT_SUMMARY' (context, value) {
      context.commit('SET_MERCHANT_SUMMARY', value)
    },
    'GET_MERCHANT_SUMMARY' (context, merchantId) {
      return CompanyInstance.merchant.getSummary(merchantId, 'finance', process.env)
        .then(res => {
          context.commit('SET_MERCHANT_SUMMARY', res)
          context.commit('SET_HAS_CONCILIATION', res.has_conciliation)
          context.dispatch('updateWhitelabel', res.whitelabel)
          if (res.whitelabel.enabled) context.dispatch('loadEmeraldByTheme', res.whitelabel.theme)
          return Promise.resolve(res)
        })
        .catch(err => catchError(err, { commit: context.commit, dispatch: context.dispatch }))
    }
  }
}
export default merchant
