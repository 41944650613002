import { FinanceInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'

const adjustments = {
  state: {
    chargeback: null,
    cancellation: null
  },
  getters: {
    adjustmentChargeback: state => {
      return state.chargeback
    },
    adjustmentChargebackCursor: state => {
      return state.chargeback ? state.chargeback.cursor : false
    },
    adjustmentCancellation: state => {
      return state.cancellation
    },
    adjustmentCancellationCursor: state => {
      return state.cancellation ? state.cancellation.cursor : false
    }
  },
  mutations: {
    SET_CHARGEBACK (state, chargeback) {
      state.chargeback = chargeback
    },
    SET_CANCELLATION (state, cancellation) {
      state.cancellation = cancellation
    }
  },
  actions: {
    GET_CHARGEBACK ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_CHARGEBACK', null)
      return FinanceInstance.adjustment.getChargeback(merchantId, query)
        .then(response => commit('SET_CHARGEBACK', response))
        .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
    },
    GET_CANCELLATION ({ commit, dispatch }, { merchantId, query }) {
      commit('SET_CANCELLATION', null)
      return FinanceInstance.adjustment.getCancellation(merchantId, query)
        .then(response => commit('SET_CANCELLATION', response))
        .catch(err => Promise.reject(catchError(err, { commit, dispatch })))
    }
  }
}
export default adjustments
