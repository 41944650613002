<template>
  <div ref="tabbar" class="emd-tabbar" :class="classList">
      <ul class="emd-tabbar__list">
        <li class="emd-tabbar__item" v-for="item in items" :key="item.key">
          <a class="emd-tabbar__link" :class="{ '_active': item.key === activeItem }" @click="action(item)">
            <span :class="itemClass">
              {{ item.value }}
            </span>
          </a>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    activeItem: {
      type: [String, Number],
      required: true
    },
    color: {
      type: String,
      required: false,
      default: null,
      validator: (value) => {
        return ['primary', 'secondary', 'tertiary', null].indexOf(value) !== -1
      }
    },
    bordered: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: null
    },
    justify: {
      type: String,
      required: false,
      default: null
    },
    itemClass: {
      type: String,
      required: false,
      default: 'emd-text'
    }
  },
  computed: {
    classList () {
      var classList = []
      if (this.bordered) {
        classList.push('_bordered')
      }
      if (this.size) {
        classList.push(`_size_${this.size}`)
      }
      if (this.justify) {
        classList.push(`_justify_${this.justify}`)
      }
      if (this.color) {
        classList.push(`_color_${this.color}`)
      }
      return classList
    }
  },
  mounted () {
    const $tabbar = this.$refs.tabbar
    let startX
    let scrollLeft
    let isDown = false
    $tabbar.addEventListener('mousewheel', function (e) {
      e.preventDefault()
      $tabbar.scrollLeft += e.deltaY
    }, false)

    $tabbar.addEventListener('mousedown', function (e) {
      isDown = true
      startX = e.pageX - $tabbar.offsetLeft
      scrollLeft = $tabbar.scrollLeft
    }, false)

    $tabbar.addEventListener('mousemove', function (e) {
      if (!isDown) return
      const x = e.pageX - $tabbar.offsetLeft
      const walk = (x - startX) * 2
      $tabbar.scrollLeft = scrollLeft - walk
    }, false)

    $tabbar.addEventListener('mouseup', function (e) {
      isDown = false
    }, false)

    $tabbar.addEventListener('touchstart', function (e) {
      isDown = true
      if (e.touches.length > 0) {
        startX = e.touches[0].pageX - $tabbar.offsetLeft
      } else {
        startX = e.pageX - $tabbar.offsetLeft
      }
      scrollLeft = $tabbar.scrollLeft
    }, false)

    $tabbar.addEventListener('touchmove', function (e) {
      if (!isDown) return
      var x
      if (e.touches.length > 0) {
        x = e.touches[0].pageX - $tabbar.offsetLeft
      } else {
        x = e.pageX - $tabbar.offsetLeft
      }
      const walk = (x - startX) * 2
      $tabbar.scrollLeft = scrollLeft - walk
    }, false)

    $tabbar.addEventListener('touchend', function (e) {
      isDown = false
    }, false)
  },
  methods: {
    action (item) {
      this.$emit('changed', item)
    }
  }
}
</script>
