<template>
  <div class="eu-display_flex eu-justify_center eu-align_items_center" style="height: 150px; overflow: hidden">
    <chart-horizontal-bar :chart-data="data" :chart-options="options" :height="120" style="width: 100%; max-width: 100%;"/>
  </div>
</template>

<script>
import ChartHorizontalBar from './horizontalBar.js'

export default {
  name: 'ChartStatsLegendDetail',
  components: {
    ChartHorizontalBar
  },
  props: {
    chartData: {
      type: Array,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      data: {
        labels: [],
        datasets: [{
          labels: [],
          data: [],
          backgroundColor: []
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontSize: 11,
            usePointStyle: true
          }
        },
        scales: {
          xAxes: [{
            display: false,
            stacked: true,
            ticks: {
              max: 100
            }
          }],
          yAxes: [{
            display: false,
            stacked: true
          }]
        },
        tooltips: {
          enabled: false
        }
      }
    }
  },
  created () {
    if (this.chartData) {
      this.updateData()
    }
  },
  watch: {
    chartData (newValue, oldValue) {
      if (newValue) this.updateData()
    }
  },
  methods: {
    updateData () {
      var totalAmount = 0
      this.chartData.forEach((item, index) => {
        totalAmount = item.header.amount.key > 0 ? totalAmount + item.header.amount.key : totalAmount
      })
      this.data = Object.assign({},
        {
          labels: [this.label],
          datasets: this.chartData.map((item, index) => ({
            label: [item.header.label],
            data: [item.header.amount.key > 0 ? ((100 * item.header.amount.key) / totalAmount).toFixed(1) : 0],
            dataLabel: [item.header.amount.value],
            backgroundColor: [item.tagColor],
            barThickness: 50,
            minBarLength: 2
          }))
        }
      )
    }
  }
}
</script>
<style>
canvas {
  width: 100% !important;
}
</style>
