<template>
  <div class="el-section">
    <div class="emd-container _highlight_header">
      <div class="emd-container__header">
        <h2 class="emd-text _size_section">{{$t('receivables')}}</h2>
        <router-link :to="{name: 'receivables'}" class="emd-text _size_small emd-link">{{$t('viewDetail')}}</router-link>
      </div>
      <div class="emd-container__content">
        <div class="eu-flex">
          <div class="eu-flex-item eu-basis_12 eu-basis_7_lg eu-mb_6 eu-no-margin_lg">
            <div class="emd-item-pagination">
              <div class="emd-item-pagination__container">

                  <partial-receivables-days />
              </div>
            </div>
          </div>
          <partial-receivables-charts />
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "receivables": "Recebimentos",
    "viewDetail": "Ver detalhes"
  },
  "en-US": {
    "receivables": "Receivables",
    "viewDetail": "View details"
  }
}
</i18n>

<script>
import PartialReceivablesCharts from './PartialReceivablesCharts.vue'
import PartialReceivablesDays from './PartialReceivablesDays.vue'

export default {
  name: 'PartialReceivables',
  components: {
    PartialReceivablesCharts,
    PartialReceivablesDays
  },
  computed: {
    payoutSummaryIsLoaded () {
      return this.$store.getters.payoutSummaryIsLoaded
    },
    payoutSummaryAcquirer () {
      return this.$store.getters.payoutSummaryAcquirer
    },
    payoutSummaryNetwork () {
      return this.$store.getters.payoutSummaryNetwork
    },
    payoutSummaryBank () {
      return this.$store.getters.payoutSummaryBank
    }
  }
}
</script>
