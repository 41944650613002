<template>
  <div class="emd-grid-custom" v-if="payoutSummaryIsLoaded">
    <div class="emd-container _type_card" v-for="(summaryDay, index) in payoutSummaryDays" :key="index">
      <div class="emd-container__header eu-column eu-justify_center" :class="bgCard(summaryDay, index)">
        <p class="emd-text _type_caption _size_small _color_default">{{summaryDay.day_of_week}}</p>
        <p class="emd-text _type_hint _size_small _color_default eu-no-margin">{{summaryDay.date}}</p>
      </div>
      <div class="emd-container__content eu-align_center">
        <p class="emd-text _type_label _size_tiny _color_primary eu-ws_nowrap">
          <i class="uil uil-check" v-if="summaryDay.received"/>
          <i class="uil uil-clock" v-else/>
          {{summaryDay.received ? $t('received') : $t('toReceive')}}
        </p>
        <p class="emd-text _type_hint _size_small fs-hide">
          <span class="emd-text _size_tiny">R$</span>
          {{summaryDay.total_amount.value}}
        </p>
      </div>
    </div>
  </div>
  <div class="el-chunk eu-py_5" v-else>
    <emd-loader :type="'_subtle'" class="eu-align_center" />
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "received": "Recebido",
    "toReceive": "A receber"
  },
  "en-US": {
    "received": "Received",
    "toReceive": "To receive"
  }
}
</i18n>

<script>
import { EmdLoader } from 'emerald-vue/src/components'

export default {
  name: 'PartialReceivablesDays',
  components: {
    EmdLoader
  },
  computed: {
    payoutSummaryIsLoaded () {
      return this.$store.getters.payoutSummaryIsLoaded
    },
    payoutSummaryDays () {
      return this.$store.getters.payoutSummaryDays
    }
  },
  methods: {
    bgCard (day, index) {
      if (index === 2) return 'eu-bg_info'
      if (day.received) return 'eu-bg_success'
      return 'eu-bg_warning'
    }
  }
}
</script>
