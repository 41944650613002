<template>
  <div>
    <partial-receivables />

    <div class="emd-label-divider">
      <label class="emd-badge">
        <emd-loader :type="'_subtle'" class="eu-align_center" v-if="!currentPeriod"/>
        <span v-else>{{currentPeriod}}</span>
      </label>
      <hr class="emd-divider"/>
     </div>

    <div class="el-section">
      <div class="eu-container">
        <div class="eu-flex">
          <partial-sales />

          <div class="eu-flex-item eu-basis_12 eu-basis_6_lg eu-mb_5">
            <partial-prepayment />

            <div class="el-section">
              <div class="eu-flex">
                <partial-adjustments :title="$t('chargebacks')"  :data="chargebacks" />
                <partial-adjustments :title="$t('cancellations')"  :data="cancellations" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "chargebacks": "Chargebacks",
    "cancellations": "Cancelamentos"
  },
  "en-US": {
    "chargebacks": "Chargebacks",
    "cancellations": "Cancellations"
  }
}
</i18n>

<script>
import PartialReceivables from './PartialReceivables'
import PartialPrepayment from './PartialPrepayment'
import PartialAdjustments from './PartialAdjustments'
import PartialSales from './PartialSales'
import { EmdLoader } from 'emerald-vue/src/components'

export default {
  name: 'Summary',
  components: {
    PartialReceivables,
    PartialPrepayment,
    PartialAdjustments,
    PartialSales,
    EmdLoader
  },
  data () {
    return {
      months: {
        'en-US': 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
        'pt-BR': 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
      }
    }
  },
  computed: {
    chargebacks () {
      const entity = this.$store.getters.adjustmentChargeback
      if (!entity) return false
      return {
        total_amount: entity.total_amount,
        total_quantity: entity.total_quantity
      }
    },
    cancellations () {
      const entity = this.$store.getters.adjustmentCancellation
      if (!entity) return false
      return {
        total_amount: entity.total_amount,
        total_quantity: entity.total_quantity
      }
    },
    currentPeriod () {
      const salesCursor = this.$store.getters.salesCursor
      if (!salesCursor) return false
      return this.months[this.$i18n.locale][salesCursor.current.split('-')[1] - 1] + ' ' + salesCursor.current.split('-')[0]
    }
  }
}
</script>
