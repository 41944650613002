<template>
  <svg id="emd-pagarme-icon" viewBox="0 0 307 316" version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="icon-logo" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
      d="M296.788,231 C296.902,228.131 297,225.26 297,222.364 C297,105.3443 204.162,10.133217 90,10 L90,79.9992 C166.606,80.0494 228.694,143.849 228.694,222.364 C228.694,225.266 228.578,228.141 228.41,231 L296.788,231 Z"
      id="pgm-path" stroke="#65A300" stroke-width="18"></path>
      <circle id="pgm-path" stroke="#65A300" stroke-width="18" fill-rule="nonzero" cx="85" cy="231" r="75">
      </circle>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EmdPagarmeIcon'
}
</script>
